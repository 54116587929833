<template>
  <div>
    <vx-card>
      <div class="mb-8">
        <h2 class="mb-0">Support</h2>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col md:w-1/2 w-full" align="left">
          <vs-input
            class="w-full search-input"
            v-model="searchQuery"
            placeholder="Search..."
          />
        </div>
      </div>
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :max-items="dataTableParams.limit"
        :data="supportData"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        @selected="handleSelected"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse flex-grow justify-between mb-5"
        >
          <div style="float: left">
            <vs-select
              placeholder="10"
              autocomplete
              v-model="dataTableParams.limit"
              class="mr-6"
              label="display per page"
            >
              <vs-select-item
                v-for="(item, index) in limitOptions"
                :key="index"
                :value="item"
                :text="item"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th>Date</vs-th>
          <vs-th>Learning Centre</vs-th>
          <vs-th>Full Name</vs-th>
          <vs-th>Phone Number</vs-th>
          <vs-th>Email</vs-th>
          <vs-th>Enquiry Type</vs-th>
          <vs-th>Status</vs-th>
          <vs-th class="justify-center">Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.Date">
              {{ moment(tr.Date).format("DD/MM/YYYY") }}
            </vs-td>
            <vs-td :data="tr.centreDetails[0].name">
              {{ tr.centreDetails[0].name }}
            </vs-td>
            <vs-td :data="tr.fullName">
              {{ tr.fullName }}
            </vs-td>
            <vs-td :data="tr.phoneNumber">
              {{ tr.phoneNumber }}
            </vs-td>
            <vs-td :data="tr.email">
              {{ tr.email }}
            </vs-td>
            <vs-td :data="tr.enquiryType">
              {{ tr.enquiryType }}
            </vs-td>
            <vs-td :data="tr.status">
              {{ tr.status === "pending" ? "Pending" : "Resolved" }}
            </vs-td>
            <vs-td class="whitespace-no-wrap text-center">
              <feather-icon
                icon="EyeIcon"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="viewDetailHandler(tr._id)"
              />
            </vs-td>
             
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2" v-if="supportData.length > 0">
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
      <br />
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        limit: 10,
        page: 1
      },
      supportData: [],
      limitOptions: ["5", "10", "25", "50", "100"],
      serverResponded: false,
      searchQuery: ""
    };
  },
  methods: {
    ...mapActions("supportEnquiry", ["getSupportEnquiryList"]),
    handleSelected(tr) {
      this.viewDetailHandler(tr._id);
    },
    moment(date) {
      return moment(date);
    },
    getSupportList() {
      this.dataTableParams.search = this.searchQuery;
      this.getSupportEnquiryList(this.dataTableParams).then(res => {
        this.supportData = res.data.data.docs;
        this.totalDocs = res.data.data.pagination.total;
        this.page = res.data.data.pagination.page;
        this.serverResponded = true;
      });
    },
    viewDetailHandler(id) {
      this.$router.push({ name: "support-view", params: { id: id } });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getSupportList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getSupportList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getSupportList();
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  watch: {
    "dataTableParams.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getSupportList();
      }
    },
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getSupportList();
      }
    },
    searchQuery: function(val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getSupportList();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    }
  },
  created() {
    this.getSupportList();
  }
};
</script>
